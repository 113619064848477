import { Component } from "@angular/core";

@Component({
    selector: 'welcome-page',
    templateUrl: 'welcome-page.component.html',
    styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent{

    
    constructor(){}
    
    
}