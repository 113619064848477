<div class="px-4 py-5 my-5 text-center">
    <h1 class="display-5 fw-bold">{{'welcome.title'|translate}}</h1>
    <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">{{'welcome.t1'| translate}}</p>
        <p class="lead mb-4">{{'welcome.t2'| translate}}</p>
        
        <p class="lead mb-4">{{'welcome.t3'| translate}}</p>

        <p class=" mb-4">{{'welcome.t4'| translate}}</p>
        
        <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <ng-content></ng-content>
        </div>
      </div>

</div>