<div class="px-4 py-3  text-center">
  <div class="col-lg-6 mx-auto">
     
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <button class="btn btn-primary btn-lg px-4 gap-3" (click)="exportAsPDF('resultContainer');">{{'result.pdf'| translate}}</button>
      </div>
  </div>

</div>
<div class="container" id="resultContainer">
    <div class="row">
      <h1 class="display-5 py-4">{{'result.title' | translate}}</h1>
    </div>

    <div class="row">
      <div class="system col-md-4 col-sm-3 col-xs-2">
        {{'system.digestive.stomach'| translate}}
      </div>
      <div class="result col-md-1 {{getColor(0)}}" >
        {{map?.get('1')}}
      </div>
    </div>
    <div class="row">
      <div class="system col-md-4">
        {{'system.digestive.intestines'| translate}}
      </div>
      <div class="result col-md-1 {{getColor(1)}}">
        {{map?.get('2')}}
      </div>
    </div>
    <div class="row">
      <div class="system col-md-4">
        {{'system.cardiovascular'| translate}}
      </div>
      <div class="result col-md-1 {{getColor(2)}}">
        {{map?.get('3')}}
      </div>
    </div>
    <div class="row">
      <div class="system col-md-4">
        {{'system.nervous'| translate}}
      </div>
      <div class="result col-md-1 {{getColor(3)}}">
        {{map?.get('4')}}
      </div>
    </div>
    <div class="row">
        <div class="system col-md-4 ">
          {{'system.immune'| translate}}
        </div>
        <div class="result col-md-1 {{getColor(4)}}">
          {{map?.get('5')}}
        </div>
    </div>
    <div class="row">
        <div class="system col-md-4">
          {{'system.respiratory'| translate}}
        </div>
        <div class="result col-md-1 {{getColor(5)}}">
          {{map?.get('6')}}
        </div>
    </div>
    <div class="row">
        <div class="system col-md-4">
          {{'system.urinary'| translate}}
        </div>
        <div class="result col-md-1 {{getColor(6)}}">
          {{map?.get('7')}}
        </div>
    </div>
    <div class="row">
        <div class="system col-md-4">
          {{'system.hormone'| translate}}
        </div>
        <div class="result col-md-1 {{getColor(7)}}">
          {{map?.get('8')}}
        </div>
    </div>
    <div class="row">
        <div class="system col-md-4">
          {{'system.muscular'| translate}}
        </div>
        <div class="result col-md-1 {{getColor(8)}}">
          {{map?.get('9')}}
        </div>
    </div>




  <div class="px-4 py-5">
    <b>{{'result.legend'| translate}}</b>

    <div class="row">
      <div class="system col-md-4">
        {{'result.A'| translate}}
      </div>
      <div class="result col-1 green">
        
      </div>
    </div>

    <div class="row">
      <div class="system col-md-4">
        {{'result.B'| translate}}
      </div>
      <div class="result col-1 yellow">
        
      </div>
    </div>

    <div class="row">
      <div class="system col-md-4">
        {{'result.C'| translate}}
      </div>
      <div class="result col-1 orange">
        
      </div>
    </div>

    <div class="row">
      <div class="system col-md-4">
        {{'result.D'| translate}}
      </div>
      <div class="result col-1 red">
        
      </div>
    </div>
  </div>
 
  <div class="row">
    <h5 class="display-5 py-4">{{'question.title'|translate}}</h5>
  </div>
  <table>
    
    <tr class="row r-350"*ngFor="let q of qa | keyvalue">
        
      <td  class="col-md-10 col-9 border">
        {{q.key | translate}}
      </td>
      <td class="col-md-2 col-2 border">{{q.value | translate}}</td>
    
    </tr>
  </table>
  
</div>
