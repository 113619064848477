import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Question } from '../model/question.model';

@Component({
    selector: 'view-item',
    templateUrl: 'view-item.component.html',
    styleUrls: ['./view-item.component.scss']
})
export class ViewItemComponent implements OnInit{
    @Input() questions: Question[];
    @Input() map: Map<string, number>;

    @Input() qa: Map<string, string>;

    @Output() surveyDone = new EventEmitter<boolean>();
    @Output() updateMap = new EventEmitter<boolean>();


    currentQuestion: Question;
    
    form: FormGroup;

    constructor(private formBuilder: FormBuilder){}
    
    ngOnInit(): void {
       this.form = this.formBuilder.group({
        answer: []
       });

       this.currentQuestion = this.questions.shift();

    }

    saveResult(valueA){
        if(valueA){
            
            this.currentQuestion.systems.forEach(s =>{
                let value = this.map.get(s);
                this.map.set(s, (+value+1));
            });
        }

        this.qa.set(this.currentQuestion.name, valueA?'1':'0');

        
        this.currentQuestion = this.questions.shift();
        this.updateMap.emit(true);
        if(!this.currentQuestion){
            this.surveyDone.emit(true);
        }

    }
}