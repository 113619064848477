import { Component , Input, OnInit} from '@angular/core';


import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
    selector: 'result-page',
    templateUrl: 'result-page.component.html',
    styleUrls: ['./result-page.component.scss']
})
export class ResultPageComponent implements OnInit{

    @Input() map: Map<string, number>;
    @Input() qa: Map<string, string>;

    colors = ['green', 'yellow', 'orange', 'red']

    colorResult =[
        [2,4,9],
        [2,4,9],
        [2,3,7],
        [2,5,10],
        [2,4,7],
        [0,1,4],
        [0,1,4],
        [2,5,10],
        [1,3,7]

    ]


    ngOnInit(): void {
        
    }

    getColor(pos: number): string{

        let sum = this.map.get((pos+1).toString());

        let colors = this.colorResult[pos];

        let index = colors.findIndex(v =>v >= sum);


        if(index==-1){
            return this.colors[3];
        }else{
            return this.colors[index];
        }

        return 'green';
    }

    exportAsPDF(divId){
        let data = document.getElementById(divId);  
        html2canvas(data, {scale: 3}).then(canvas => {
 
        const imgWidth = 205;
        const pageHeight = 292;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jspdf.jsPDF('p', 'mm', 'a4');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
        



        doc.save('test_result.pdf');   
      }); 
    }

}