import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Question } from './model/question.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    questions : Question[];
    currentQuestion: Question;
    resultMap = new Map<string, number>();
    infoText = true;
    survey = false;
    showResults = false;
    showWelcomePage = true;

    qa = new Map<string, string>();
    

  constructor(private modalService: NgbModal){}
  ngOnInit(): void {
    this.questions = [
      {name: "q1", systems:['1','3','4','5','8'], order: 1},
      {name: "q2", systems:['5'], order: 2},
      {name: "q3", systems:['1','2','6','7'], order: 3},
      {name: "q4", systems:['1','5'], order: 4},
      {name: "q5", systems:['2','3','6'], order: 5},
      {name: "q6", systems:['2','7','8'], order: 6},
      {name: "q7", systems:['2','5'], order: 7},
      {name: "q8", systems:['4','8'], order: 8},
      {name: "q9", systems:['4','8'], order: 9},
      {name: "q10", systems:['1','5','6'], order: 10},
      {name: "q11", systems:['3','4','7'], order: 11},
      {name: "q12", systems:['3','4','6'], order: 12},
      {name: "q13", systems:['3','4','8'], order: 13},
      {name: "q14", systems:['1','5'], order: 14},
      {name: "q15", systems:['1','5'], order: 15},
      {name: "q16", systems:['3','4','5','8'], order: 16},
      {name: "q17", systems:['1','2','7','8','9'], order: 17},
      {name: "q18", systems:['4','8'], order: 18},
      {name: "q19", systems:['2'], order: 19},
      {name: "q20", systems:['2','4'], order: 20},
      {name: "q21", systems:['4','8'], order: 21},
      {name: "q22", systems:['4','8','9'], order: 22},
      {name: "q23", systems:['7'], order: 23},
      {name: "q24", systems:['3','4','8','9'], order: 24},
      {name: "q25", systems:['3','5','9'], order: 25},
      {name: "q26", systems:['4','5','8','9'], order: 26},
      {name: "q27", systems:['3','6','9'], order: 27},
      {name: "q28", systems:['1','2','8'], order: 28},
      {name: "q29", systems:['2','3','5','8'], order: 29},
      {name: "q30", systems:['1','2','4'], order: 30},
      {name: "q31", systems:['1','4','8'], order: 31},
      {name: "q32", systems:['8'], order: 32},
      {name: "q33", systems:['1','9'], order: 33},
      {name: "q34", systems:['1','7'], order: 34},
      {name: "q35", systems:['1','2','3'], order: 35},
      {name: "q36", systems:['4','8'], order: 36},
      {name: "q37", systems:['2','3'], order: 37},
      {name: "q38", systems:['4','9'], order: 38},
      {name: "q39", systems:['5','6'], order: 39},
      {name: "q40", systems:['4','8','9'], order: 40},
      {name: "q41", systems:['4','5','9'], order: 41},
      {name: "q42", systems:['1','2','5'], order: 42},
      {name: "q43", systems:['1','2','5','7'], order: 43},      
      {name: "q44", systems:['1','9'], order: 44},
      {name: "q45", systems:['1','4'], order: 45},
      {name: "q46", systems:['2','4','8'], order: 46},
      {name: "q47", systems:['2','4','8','9'], order: 47},
      {name: "q48", systems:['2','6'], order: 48},

      
    ];
    for(let i=1; i<10; i++){
      this.resultMap.set(i.toString(),0);
    }
    

   
  }


  public saveResults(){
    this.showResults = true;
  }

  public updateMap(){
  }

  public startSurvey(){
    this.showWelcomePage = false;
  }

}
