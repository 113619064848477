<div [formGroup]="form">


    <div *ngIf="currentQuestion">

        <div class="px-4 py-5 my-5 text-center">

            <p class="lead mb-4">Pytanie {{currentQuestion.order}} z 48</p>
            
            <h1 class="display-5 py-4">{{currentQuestion.name | translate}}</h1>
            <div class="col-lg-6 mx-auto">
        
                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center">

                    <button type="button" class="btn btn-outline-primary btn-lg px-4 gap-3" (click)="saveResult(true)">Tak</button>
                    
                    <button type="button" class="btn btn-outline-primary btn-lg px-4 " (click)="saveResult()">Nie</button>
     



                </div>

            </div>

            
        </div>

    </div>

</div>

