import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {registerLocaleData} from "@angular/common";

import ru from '@angular/common/locales/ru';
import pl from '@angular/common/locales/pl';


@Injectable({
  providedIn: 'root'
})
export class LangTranslateService {

  constructor(private translateService: TranslateService) {
  }

  init() {
    this.registerLocales();

    const chosenLanguage = this.translateService.getBrowserLang();
    this.translateService.addLangs(['pl', 'ru']);
    console.log(chosenLanguage);
    if(['pl','ru'].indexOf(chosenLanguage)>0){
      this.translateService.use(chosenLanguage);
    } else{
      this.translateService.use('pl');
    }

  }

  registerLocales() {
    registerLocaleData(ru);
    registerLocaleData(pl);
  }

}